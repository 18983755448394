@import url("https://fonts.googleapis.com/css?family=Quicksand");
@import "base/typography";
@import "base/bs-var";
@import "base/bs";
.card-columns {
    @include media-breakpoint-only(lg) {
        column-count: 4;
    }
    @include media-breakpoint-only(xl) {
        column-count: 5;
    }
}

hr {
    border-color: $white;
}

h1 {
    padding: 20px;
}

.lead {
    font-size: 1.2rem;
}

.store-info {
    margin: 20px 0;
    text-align: justify;
}

.card {
    padding: 14px;
    background-color: $gray-800;
}

#main-bg {
    background-image: url('../images/pics/lg/main-bg.jpg');
    // filter: brightness(.3);
    background-size: cover;
}

.center-svg {
    margin: 0 auto;
}

#main-svg {
    margin: 50px auto;
}

#main-logo {
    margin: 50px auto;
    // position: absolute;
    // top: -33px;
    // padding: 60px;
    // left: -10px;
    visibility: hidden;
    display: none;
}

#light {
    margin: 50px auto;
    position: absolute;
    top: 0px;
    display: none;
}
.navbar-brand {
    max-width: 190px;
    white-space: normal;
    text-align: center;
}

.navbar-inverse .navbar-nav .nav-link {
    color: $green;
}

.navbar {
    border-bottom: solid 2px $green;
}

.mast-head {
    // float: right;
}

.parallax-text {
    position: relative;
}

div#about-mission {
    background-image: url('../images/pics/lg/about-tall.jpg');
    filter: brightness(.3);
    background-position: 50% 25%;
    background-attachment: fixed;
    width: 100%;
    height: 450px;
    padding: 40px;
    margin-bottom: 30px;
    color: #FFFFFF;
}

p#about-mission-text {
    color: #FFFFFF;
    position: absolute;
    top: 80px;
    left: 10%;
    width: 80%;
    text-align: center;
}

.gmap-locations {
    width: 100%;
    height: 250px;
}

div#brands {
    // background-color: $gray-200;
}
    .commercial-pic {
        margin-bottom: 20px;
}

@include media-breakpoint-up(md) {
    .commercial-pic {
        max-width:500px;
        float: right;
        margin: 10px 0 20px 40px;
    }
}

@include media-breakpoint-down(sm) {
    p#about-mission-text {
        font-size: 30px;
        top: 60px;
    }
    div#about-mission {
        height: 300px;
    }
}

@include media-breakpoint-down(md) {
    p#about-mission-text {
        top: 40px
    }
}

div#contact {
    li {
        list-style: none;
    }
}

footer {
    margin-top: 50px;
    p {
        margin: 4px;
    }
}